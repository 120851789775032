import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

import { Container, Inner } from './Elements';
import { introTypes } from '../types/propTypes';
import { colors, font } from '../consts/style';
import mq from '../style/mq';

const Wrapper = styled.div`
  background: white;
  color: ${colors.darkBlue};
  padding: 4rem 0;

  ${Inner} {
    display: flex;
    align-items: center;
    .media {
      flex-shrink: 0;
      width: 44%;
      min-width: 44rem;
      max-width: 100%;
      img,
      video {
        width: 100%;
      }
    }
    .body {
      width: 100%;
      flex-shrink: 1;
      img {
        max-height: 5rem;
        max-width: 100%;
        margin-bottom: 2rem;
      }
      h3 {
        margin-bottom: 2rem;
        ${font.bodyHeading};
      }
      p {
        ${font.body};
      }
    }
    ${mq.tablet`
    flex-wrap: wrap;
    .media {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
    }
    `}
  }
`;

const Image = styled(Img)`
  width: 100%;
  max-width: 100%;
`;

export default function PlugIntro({
  logo,
  title,
  body,
  introMp4,
  introWebm,
  introImage,
  isSmall,
}) {
  return (
    <Wrapper>
      <Container>
        <Inner>
          <div className="media">
            {isSmall ? (
              <Image fluid={introImage.fluid} />
            ) : (
              // eslint-disable-next-line jsx-a11y/media-has-caption
              <video autoPlay muted loop poster={introImage.url}>
                <source src={introMp4.url} type="video/mp4" />
                <source src={introWebm.url} type="video/webm" />
              </video>
            )}
          </div>
          <div className="body">
            <img src={logo} alt="Icon" />
            <h3>{title}</h3>
            <p>{body}</p>
          </div>
        </Inner>
      </Container>
    </Wrapper>
  );
}

PlugIntro.propTypes = introTypes;
