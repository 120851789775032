import React, { useState } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import _map from 'lodash/map';

import { Container, Inner } from './Elements';
import { diagramTypes } from '../types/propTypes';
import { colors, font } from '../consts/style';
import mq from '../style/mq';

import Plus from '../images/plus.svg';
import breakpoints from '../consts/breakpoints';
import Video from './Video';

const Wrapper = styled.div`
  min-height: 100vh;
  position: relative;
  background: white;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 52rem;
    background: ${colors.blue};
    z-index: 0;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    z-index: 1;
    border-left: 4rem solid transparent;
    border-right: 4rem solid transparent;
    border-top: 4rem solid ${colors.teal};
  }
  ${Container} {
    position: relative;
    z-index: 10;
    padding-top: 12rem;
    padding-bottom: 6rem;
    ${Inner} {
      display: flex;
    }
  }
`;

const DiagramCol = styled.div`
  width: 65rem;
  flex-shrink: 0;
  .diagram {
    width: 58.4rem;
    position: relative;
  }

  .icon {
    img {
      width: 90%;
    }
  }

  ${mq.diagram`
  .icon {
    display: none;
  }
  margin: 0 auto;
  max-width: 100%;
  img {
    max-width: 100%;
  }
  .diagram {
    width: 100%;
    img {
      width: 28rem;
      max-width: 100%;
    }
  }
  `}
`;

const InfoCol = styled.div`
  width: 100%;
  .title {
    margin-top: 2rem;
    margin-bottom: 4rem;
    flex-shrink: 1;
    position: relative;
    color: white;
    .copy {
      h2 {
        ${font.h1};
      }
    }
  }
  .body1 {
    ${font.body};
    margin-bottom: 4rem;
    color: white;
  }
  .title2 {
    color: ${colors.teal};
    text-transform: uppercase;
    margin: 4rem 0 2rem;
    h2 {
      ${font.bodyHeading};
    }
  }
  .body2 {
    ${font.body};
  }
  .steps {
    margin-top: 4rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    .step {
      text-align: center;
      width: 100%;
      max-width: 14rem;
      img {
        width: 100%;
      }
      p {
        ${font.body};
        text-transform: uppercase;
        font-weight: bold;
        color: ${colors.blue};
        margin-top: 1.2rem;
      }
    }
  }
  .buttons {
    display: flex;

    flex-wrap: wrap;
    button {
      ${font.button};
      margin-right: 4rem;
    }
    a {
      padding: 2rem 0;
    }
    .available {
      display: flex;
      align-items: center;
      ${font.body};
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-top: 1.2rem;
      a {
        margin-left: 2rem;
      }
      img {
        max-width: 100%;
        max-height: 7.2rem;
      }
    }
  }
  ${mq.diagram`
    display: none;
  `}
`;

const DiagramImage = styled(Img)`
  width: 100%;
  ${mq.diagram`
  width: 28rem;
  max-width: 100%;
  margin: auto;
  `}
`;

const Point = styled.div`
  display: none;
  ${mq.diagram`
    display: none !important;
  `}
  img {
    width: 4rem;
  }
  .blurb {
    background: ${colors.teal};
    color: white;
    padding: 2.4rem;
    width: 47.5rem;
    border-radius: 0 4.2rem 4.2rem 4.2rem;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(92%) translateX(96%);
    border: 1px solid #707070;
    transition: all 0.3s ease;
    opacity: 1;
    pointer-events: all;
    &.hidden {
      opacity: 0;
      bottom: 4rem;
      right: 4rem;
      pointer-events: none;
    }
    p {
      margin: 0;
      ${font.body};
      &.title {
        font-weight: bold;
        padding-bottom: 1.2rem;
        text-transform: uppercase;
      }
    }
  }
  z-index: 10;
  &.point-0 {
    display: block;
    position: absolute;
    top: 10rem;
    left: 17rem;
    z-index: 20;
  }
  &.point-1 {
    display: block;
    position: absolute;
    top: 23rem;
    left: 13rem;
    z-index: 20;
  }
  &.point-2 {
    display: block;
    position: absolute;
    top: 44rem;
    left: 13rem;
    z-index: 20;
  }
  &.point-3 {
    display: block;
    position: absolute;
    top: 64rem;
    left: 16rem;
    z-index: 20;
  }
  &.point-4 {
    display: block;
    position: absolute;
    top: 82rem;
    left: 19rem;
    z-index: 20;
  }
  &.point-5 {
    display: block;
    position: absolute;
    top: 102rem;
    left: 15rem;
    z-index: 20;
  }
  &.point-6 {
    display: block;
    position: absolute;
    top: 22rem;
    left: 44rem;
  }
  &.point-7 {
    display: block;
    position: absolute;
    top: 55.5rem;
    left: 44rem;
  }
  &.point-8 {
    display: block;
    position: absolute;
    top: 62rem;
    left: 44rem;
  }
  &.point-9 {
    display: block;
    position: absolute;
    top: 92rem;
    left: 44rem;
  }
  &.point-10 {
    display: block;
    position: absolute;
    top: 117rem;
    left: 44rem;
  }
`;

const MobileView = styled.div`
  @media screen and (min-width: ${breakpoints.diagram}px) {
    display: none !important;
  }
  .mb1 {
    color: white;
    ${font.body};
  }
  .mt1 {
    h2 {
      ${font.h1};
    }
    line-height: 150%;
    color: white;
    margin-bottom: 3.2rem;
  }
  .steps {
    margin-top: 4rem;
    margin-bottom: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .step {
      text-align: center;
      width: 40%;
      img {
        width: 16rem;
        max-width: 100%;
      }
      p {
        ${font.body};
        text-transform: uppercase;
        font-weight: bold;
        color: ${colors.blue};
        margin-top: 1.2rem;
      }
    }
  }
  .buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 2.4rem;

    button {
      display: none;
      ${font.button};
    }

    .available {
      display: flex;
      align-items: center;
      ${font.body};
      justify-content: flex-start;
      margin-top: 1.2rem;
      width: 100%;
      img {
        height: 7.2rem;
      }
      .text {
        margin-right: 2rem;
      }
      .vendor-wrap {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  a {
    &.diagram-button {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 80%;
      text-align: center;
      transform: translateX(-50%);
      ${font.button};
    }
  }
`;

export default function PlugDiagram({
  diagramIcon,
  diagramImage,
  diagramInfoHeading1,
  diagramInfoHeading2,
  diagramInfoBody1,
  diagramInfoBody2,
  diagramPoints,
  howToIcons,
  salesSheet,
  vendors,
  diagramVideoId,
}) {
  const [indexShown, setShown] = useState(new Array());

  function renderPoints() {
    return _map(diagramPoints, (dp, i) => {
      return (
        <Point
          onMouseEnter={() => handlePointAdd(i)}
          key={dp.id}
          className={`point-${i}`}
        >
          <img src={Plus} alt="Plus icon" />
          <div className={`blurb ${checkIndex(i)}`}>
            <p className="title">{dp.title}</p>
            <p className="body">{dp.body}</p>
          </div>
        </Point>
      );
    });
  }

  function handlePointAdd(i) {
    setShown([i]);
  }

  function checkIndex(i) {
    if (indexShown.includes(i)) return '';
    return 'hidden';
  }

  return (
    <Wrapper>
      <Container>
        <Inner>
          <DiagramCol onMouseLeave={() => handlePointAdd('')}>
            <div className="icon">
              <img src={diagramIcon} alt="Icon" />
            </div>
            <MobileView>
              <div className="title">
                <div className="mt1">
                  <h2>{diagramInfoHeading1}</h2>
                </div>
              </div>
              <div className="mb1">
                <p>{diagramInfoBody1}</p>
              </div>
            </MobileView>
            <div className="diagram">
              <DiagramImage fluid={diagramImage} />
              {renderPoints()}
              <MobileView>
                <a
                  className="diagram-button"
                  target="blank"
                  href={salesSheet.url}
                >
                  <span>Download Sales Sheet</span>
                </a>
              </MobileView>
            </div>
            <MobileView>
              <Video videoId={diagramVideoId} path="/plug" id="plug-video" />
              <div className="steps">
                {_map(howToIcons, h => {
                  return (
                    <div key={`${h.url}-m`} className="step">
                      <img src={h.url} alt="Foo" />
                    </div>
                  );
                })}
              </div>
              <div className="buttons">
                <a target="blank" href={salesSheet.url}>
                  <button>
                    <span>Download Sales Sheet</span>
                  </button>
                </a>
                <div className="available">
                  <div className="text">
                    AVAILABLE
                    <br /> THROUGH:
                  </div>
                  <div className="vendor-wrap">
                    {vendors.map(v => (
                      <a target="blank" key={v.id} href={v.link}>
                        <img src={v.image.url} alt="Available through" />
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </MobileView>
          </DiagramCol>
          <InfoCol>
            <div className="title">
              <div className="copy">
                <h2>{diagramInfoHeading1}</h2>
              </div>
            </div>
            <div className="body1">
              <p>{diagramInfoBody1}</p>
            </div>
            <Video
              videoId={diagramVideoId}
              path="/plug"
              id="plug-video-mobile"
            />
            <div className="title2">
              <h2>{diagramInfoHeading2}</h2>
            </div>
            <div className="body2">
              <p>{diagramInfoBody2}</p>
            </div>
            <div className="steps">
              {_map(howToIcons, h => {
                return (
                  <div key={h.url} className="step">
                    <img src={h.url} alt="Foo" />
                  </div>
                );
              })}
            </div>
            <div className="buttons">
              <a target="blank" href={salesSheet.url}>
                <button>
                  <span>Download Sales Sheet</span>
                </button>
              </a>
              <div className="available">
                <div className="text">
                  AVAILABLE
                  <br /> THROUGH:
                </div>
                <div className="vendor-wrap">
                  {vendors.map(v => (
                    <a target="blank" key={v.id} href={v.link}>
                      <img src={v.image.url} alt="Available through" />
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </InfoCol>
        </Inner>
      </Container>
    </Wrapper>
  );
}

PlugDiagram.propTypes = diagramTypes;
