import React from 'react';
import styled from 'styled-components';
import map from 'lodash/map';

import { Container, Inner } from './Elements';
import { infoTypes } from '../types/propTypes';
import { font } from '../consts/style';
import mq from '../style/mq';
import useTheme from '../hooks/useTheme';

const Wrapper = styled.div`
  background: ${({ color }) => color};
  color: white;
  padding: 6rem 0;
  ${mq.tablet`
       padding: 4rem 0;
    `}
  ${Inner} {
    display: flex;
    ${mq.tablet`
      flex-wrap: wrap;
    `}
  }
`;
const Block = styled.div`
  width: 33.334%;
  padding: 0 4rem;
  max-width: 100%;
  border-right: 0.4rem solid white;
  &:nth-child(3n) {
    padding-right: 0;
    border-right: none;
  }
  .block-inner {
    width: 42rem;
    max-width: 100%;
    ${font.body};
    color: white;
    .icon {
      text-align: center;

      margin: 0 auto 2rem;
      img {
        height: 14rem;
      }
    }
    p {
      margin: 0;
    }
    .title {
      ${font.h1};
      padding-top: 4rem;
      padding-bottom: 1.2rem;
    }
  }
  ${mq.tablet`
      width: 100%;
      border-bottom: 0.3rem solid white;
      border-right: none;
      padding: 6rem 0;
      &:nth-child(3n) {
        border-right: none;
        border-bottom: none;
      }
      .block-inner {
        width: 50rem;
        margin: 0 auto;
      }
  `}
`;

export default function InfoBlocks({ blocks, path }) {
  const theme = useTheme(path);
  function renderBlocks() {
    return map(blocks, b => {
      return (
        <Block key={b.id}>
          <div className="block-inner">
            <div className="icon">
              <img src={b.icon && b.icon.url} alt="Icon" />
            </div>
            <p className="title">{b.title}</p>
            <p>{b.body}</p>
          </div>
        </Block>
      );
    });
  }

  return (
    <Wrapper color={theme.color}>
      <Container>
        <Inner>{renderBlocks()}</Inner>
      </Container>
    </Wrapper>
  );
}

InfoBlocks.propTypes = infoTypes;
