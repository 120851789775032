import React, { useEffect } from 'react';
import styled from 'styled-components';

import { videoTypes } from '../types/propTypes';
import useTheme from '../hooks/useTheme';

const Wrapper = styled.div`
  width: 100%;
  .plyr--full-ui
    input[type='range'].plyr__tab-focus::-webkit-slider-runnable-track {
    box-shadow: 0 0 0 5px ${({ theme }) => theme.transparent};
    outline: 0;
  }

  .plyr--full-ui input[type='range'].plyr__tab-focus::-moz-range-track {
    box-shadow: 0 0 0 5px ${({ theme }) => theme.transparent};
    outline: 0;
  }

  .plyr--full-ui input[type='range'].plyr__tab-focus::-ms-track {
    box-shadow: 0 0 0 5px ${({ theme }) => theme.transparent};
    outline: 0;
  }

  .plyr__control.plyr__tab-focus {
    box-shadow: 0 0 0 5px ${({ theme }) => theme.transparent};
    outline: 0;
  }

  .plyr--audio .plyr__control.plyr__tab-focus,
  .plyr--audio .plyr__control:hover,
  .plyr--audio .plyr__control[aria-expanded='true'] {
    background: ${({ theme }) => theme.color};
    color: #fff;
  }

  .plyr--video .plyr__control.plyr__tab-focus,
  .plyr--video .plyr__control:hover,
  .plyr--video .plyr__control[aria-expanded='true'] {
    background: ${({ theme }) => theme.color};
    color: #fff;
  }

  .plyr__control--overlaid {
    background: ${({ theme }) => theme.transparent};
    border: 4px solid ${({ theme }) => theme.color};
    border-radius: 50%;
    color: #fff;
    display: none;
    left: 50%;
    padding: 15px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  .plyr--full-ui input[type='range'] {
    color: ${({ theme }) => theme.color};
  }
`;

export default function Video({ videoId, id, path }) {
  const theme = useTheme(path);
  let vid;

  useEffect(() => {
    createVideo();
    return () => destroyVideo();
  }, []);

  function createVideo() {
    if (typeof window !== 'undefined') {
      const plyr = require('plyr');
      vid = new plyr(`#${id}`);
    }
  }

  function destroyVideo() {
    if (vid) vid.destroy();
  }

  return (
    <Wrapper theme={theme}>
      <div id={id}>
        <iframe
          title={`Video for ${path}`}
          src={`https://player.vimeo.com/video/${videoId}?loop=true&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media`}
          allowfull="true"
          allowtransparency="true"
          allow="autoplay"
        ></iframe>
      </div>
    </Wrapper>
  );
}

Video.propTypes = videoTypes;
